import React from 'react';
import "../styles/navbar.css"

const Blogs = () => {
return (
	<h1>You can write your blogs!</h1>
);
};

export default Blogs;
